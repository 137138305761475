import { I18nOptions, createI18n } from 'vue-i18n'
import zh from './language/zh'
import en from './language/en'

const messages = {
	zh,
	en
} as I18nOptions['messages'];

let locale = localStorage.getItem('locale') || 'zh'
//i18n配置
const i18n = createI18n({
	legacy: false, // 在Composition API模式下可用
	globalInjection: true, // 全局生效$t
	locale: locale, // 默认中文
	messages,
})


// 切换语言
export function changeLocale(language: any) {
	console.log('系统切换语言', language)
	const locale = messages && messages[language] ? language : 'zh'
	i18n.global.locale.value = locale
	localStorage.setItem('locale', locale)
}

export default i18n
