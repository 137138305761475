export default {
	selection1: {
		title: '位置轨迹，共享不孤单',
		part1: {
			title: '【甜蜜聊天】',
			content: '即时聊天，字里行间都是恋爱的味道在恋爱日常，可发送文字、表情、语音、图片，还有更多有趣的互动功能。'
		},
		part2: {
			title: '【桌面传图】',
			content: '桌面小组件就是你们的图片接收器，拍摄画面实时同步，好看的你有趣的事就是要第一时间霸占对方的屏幕。'
		}
	},
	selection2: {
		title: '设备信息，双重守护',
		part1: {
			title: '【纪念日】',
			content: '记录重要的日子，可选择提醒方式，到达指定时间时，将会提醒情侣纪念日，让情侣不再担心忘记特殊的日子啦。'
		},
		part2: {
			title: '【心情日记】',
			content: '把每天的日常和心情写入日记，双方能互相看到对方的心情变化，开心时相互分享，伤心时有我安慰，这才是情侣日记的记录。'
		}
	},
	selection3: {
		title: '距离查看我爱你打卡',
		part1: {
			title: '【查看距离】',
			content: "距离实时查看，相隔再远也不用担心。双方共享位置，异地恋的福音。"
		},
		part2: {
			title: '看电影',
			content: '深夜一起看电影，再也不用担心一个人看电视剧了。'
		}
	}
}