import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import "amfe-flexible"
import './assets/css/common.css'
import '@/utils/scss/reset.scss'
import i18n from './i18n'


const pinia = createPinia()

createApp(App)
	.use(i18n)
    .use(router)
    .use(pinia)
    .mount('#app')
