import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [{
    path: "/",
    name: "home",
    component: () => import("../view/Home.vue"),
    meta: {
        title: "LinkUp"
    }
}, {
    path: "/m",
    name: "mobile",
    component: () => import("../view/Mobile.vue"),
    meta: {
        title: "LinkUp"
    }
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        }

        return
    }
})

router.beforeEach((to, from, next) => {
    next()
})

export default router