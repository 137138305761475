export default {
	selection1: {
		title: 'Sweet Chat&Snap',
		part1: {
			title: '【Chat】',
			content: 'Instant chating, with the flavor of love between the lines. In Closer, you can send text, voice, and more interesting features.'
		},
		part2: {
			title: '【Snap】',
			content: 'Receive images through desktop widgets, synchronize the captured images in real-time, Share the beautiful pictures with your partner as soon as possible.'
		}
	},
	selection2: {
		title: 'Record every moment between couples',
		part1: {
			title: '【Anniversary】',
			content: 'Record important days and choose the reminder method. When the specified time is reached, you will be reminded of your anniversary, so you no longer worry about forgetting special days.'
		},
		part2: {
			title: '【Diary】',
			content: 'Record daily diaries, and together record memories of your love story in the Closer.'
		}
	},
	selection3: {
		title: 'Location & Binge-watch',
		part1: {
			title: '【location】',
			content: "Real time viewing of location, no matter how far apart you are, you don't have to worry.The gospel of long-distance love where both parties share a location."
		},
		part2: {
			title: '【Binge-watch】',
			content: 'Watching a movie together late at night, you no longer have to worry about watching a TV series alone.'
		}
	}
}